import { IScheduleDailyFrequency } from 'core/models/schedule-reports';
import { ISelectFilterOptions } from 'core/models/filter';

export enum Position {
  Absolute = 'absolute'
}

export enum DateFormatters {
  MMMDDYYY = 'MMM DD, YYYY',
  DOMMMMYYYYHMMA= 'MMM D, YYYY | h:mm A',
  MMMMYYYY = 'MMMM, YYYY',
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const defaultPageIndex = 1;

export const Interval = 1;

export const EndDateMax = 366;

export const StartDateMax = 31;

export const defaultPageSize = 25;

export const FileType = 'CSV';

export const defaultValueForLastRunOnFromServer = '0001-01-01T00:00:00Z';

export const enum ScheduleAuditKeys {
  Frequency = 'Frequency',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  Slot = 'Slot',
  Teams = 'Teams',
  Groups = 'Groups',
  Roles = 'Roles',
  Users = 'Users',
  ScheduleMode = 'ScheduleMode'
}

export const enum ScheduleRecipientTypes {
  Self = 'Self',
  SpecificSet = 'SpecificSet',
}

export const scheduleFrequencyDailyList : Array<IScheduleDailyFrequency> = [
  {
    label: 'S',
    value: 0,
    selected: true,
    showTooltip: false,
    pendoClassName: 'se-track-schedule-freq-day-Sunday',
  },
  {
    label: 'M',
    value: 1,
    selected: true,
    showTooltip: false,
    pendoClassName: 'se-track-schedule-freq-day-Monday',
  },
  {
    label: 'T',
    value: 2,
    selected: true,
    showTooltip: false,
    pendoClassName: 'se-track-schedule-freq-day-Tuesday',
  },
  {
    label: 'W',
    value: 3,
    selected: true,
    showTooltip: false,
    pendoClassName: 'se-track-schedule-freq-day-Wednesday',
  },
  {
    label: 'T',
    value: 4,
    selected: true,
    showTooltip: false,
    pendoClassName: 'se-track-schedule-freq-day-Thursday',
  },
  {
    label: 'F',
    value: 5,
    selected: true,
    showTooltip: false,
    pendoClassName: 'se-track-schedule-freq-day-Friday',
  },
  {
    label: 'S',
    value: 6,
    selected: true,
    showTooltip: false,
    pendoClassName: 'se-track-schedule-freq-day-Saturday',
  },
];

export enum Frequency {
  daily = 0,
  weekly,
  monthly
}

export enum MonthlyFrequencyTypes {
  absolute = 'Absolute',
  relative ='Relative'
}

export const frequencyList: Array<ISelectFilterOptions> = [
  {
    label: 'Daily',
    value: 0,
  },
  {
    label: 'Weekly',
    value: 1,
  },
  {
    label: 'Monthly',
    value: 2,
  },
];

export const scheduleFrequencyWeeklyList: Array<ISelectFilterOptions> = [
  {
    label: '1 week',
    value: 0,
  },
  {
    label: '2 week',
    value: 1,
  },
  {
    label: '3 week',
    value: 2,
  },
  {
    label: '4 week',
    value: 3,
  },
];

export const dayNumberValues = Array.from({ length: 31 }, (_, index) => ({ label: `${index + 1}`, value: index + 1 }));

export const RelativeOccurrenceList: Array<ISelectFilterOptions> = [
  {
    label: 'First',
    value: 0,
  },
  {
    label: 'Second',
    value: 1,
  },
  {
    label: 'Third',
    value: 2,
  },
  {
    label: 'Fourth',
    value: 3,
  },
  {
    label: 'Last',
    value: 4,
  },
];

export const relativeDayNameList: Array<ISelectFilterOptions> = [
  {
    label: 'Sunday',
    value: 0,
  },
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
  {
    label: 'Day',
    value: 7,
  },
];

export const scheduleStatusList: Array<ISelectFilterOptions> = [
  {
    label: 'Active',
    value: 'Active',
  },
  {
    label: 'Inactive',
    value: 'Inactive',
  },
];

export const scheduleExportTypes: Array<ISelectFilterOptions> = [
  {
    label: 'CSV',
    value: 'CSV',
  },
  {
    label: 'PDF',
    value: 'PDF',
  },
];

export enum ScheduleStatus {
  active = 'Active',
  inActive = 'Inactive',
}

export const getFrequencyType:any = {
  0: 'Daily',
  1: 'Weekly',
  2: 'Monthly',
};

export const daysOfWeek:any = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Day',
};

export const getMonthlyRelativeOccurence: any = {
  0: 'First',
  1: 'Second',
  2: 'Third',
  3: 'Fourth',
  4: 'Last',
};

export enum RecipientCategories {
  TeamId = 'TeamId',
  GroupId = 'GroupId',
  Role = 'Role',
  UserId = 'UserId'
}
