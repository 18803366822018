import {
  ClickSource,
  CompactTypes,
  LoadingStatus,
  PanelSourceTypes,
  defaultRowHeight,
} from 'core/constants/dashboard';
import { VisualizationTypes } from 'core/constants/visualizations';
import { SelectedRow } from 'services/report.service';
import { IReportSection } from 'redux-v2/report-listing/report-listing.state';
import { APIRequestStatus } from 'core/constants/redux';
import { statusCodes } from 'core/constants/report';
import { DashboardAction } from 'redux-v2/dashboard-store/dashboard-store.state';
import { FeatureContext } from 'core/constants/common';
import { DrilldownConfigMapping } from 'redux-v2/report-builder/report-builder-store.state';
import { ObjModel, ReportResponseModel } from '.';
import { IFilterConfig, IFiltersMetaData } from './report-response';
import { IPaging, IReportConfig, ReportType } from './report-builder/report-builder';
import { IChartAttributes } from './report-redux';
import { Auth } from './auth-response';
import { Obj, ObjGeneric } from './obj';
import { IFilterResponse } from './filter';

export interface IDashboardItem {
  TitleSection: ITitleSection;
  Id: string;
}

export interface IDashboardList {
  loadingStatus: LoadingStatus;
  dashboardItems: Array<IDashboardItem>;
}

export interface IDashboardConfig {
  _id?: string;
  Id: string;
  CreatedBy?: string;
  TitleSection: ITitleSection;
  Type: ReportType;
  Visualization: IDashboardVisualization;
  Filters: Array<IFilterConfig>;
  Panels: Array<IPanel>;
  IsHome: boolean;
  IsPersonalized: boolean;
  IsPinned: boolean;
  FiltersMetadata?: IFiltersMetaData;
}
export interface IFilterStore {
    selectedFilter: IFilterItem,
    dynamicGlobalFilters: Array<IFilterItem>;
    staticGlobalFilters: Array<IFilterItem>;
    updatedFilterIds:Array<string>,
    reportMappedFilters: IReportMappedFilters;
    updatePanelList: Array<string>,
    panelMappedPartitionColumns: IReportMappedColumns,
    latestModifiedFilterId:string,
}
export interface IFilterLinkInfo{
  [key: string]: any
}

export interface IFilterList {
[key: string]: IFilterItem
}
export interface IFilterItem extends IFilterConfig{
  linkedPanelFilter?: IFilterLinkInfo,
  IsApplied?: boolean,
  IsEditing?: boolean,
  hide?: boolean,
  filterData?: IFilterResponse,
  filterLoadData?:boolean,
  filterLinkedKey?: string,
  alwaysHidden?:boolean,
  delinkedFilterIds?:string[],
  Status?:string,
}
export interface ITitleSection {
  Title: string;
  Description: string;
}

export interface IDashboardVisualization {
  Margin: [number, number];
  CompactType: CompactTypes;
  RowHeight: number;
  IsDraggable: boolean;
  IsResizable: boolean;
}

export interface IPanel {
  Id: string;
  TitleSection: ITitleSection;
  Source: IPanelSource;
  Target: {
    Type: VisualizationTypes;
    GridLayout: {
      x: number;
      y: number;
      w: number;
      h: number;
      i: string;
      minW?: number;
      maxW?: number;
      minH?: number;
      maxH?: number;
      isDraggable?: boolean;
      isResizable?: boolean;
    };
    Visualization: IPanelVisualisation;
  };
  FeatureContext: FeatureContext
}

export interface ISource {
  SourceType: PanelSourceTypes;
  SourceId: string;
  ReportId: string;
  PageSize: number;
  Url?: string;
}

export interface IPanelSource extends ISource {
  GlobalFiltersMapping: Object;
}

export interface IPanelVisualisation {
  Builder: any; // an object with key as Columns and value as array of strings
  Type: VisualizationTypes;
  DisplayName?: string;
  DrilldownConfigMapping?: DrilldownConfigMapping,
  DrilldownConfigIdMapping?: DrilldownConfigMapping,
  DrilldownPagination?: IPaging,
}

export interface IPanelDataResponse extends Auth {
  Raw: PanelRawData;
  Visualization: IResponseVisualizationBuilder;
}

export interface IResponseVisualizationBuilder {
  Columns?: Array<ReportResponseModel.IColumn>;
  IsRestricted?: boolean;
}

export interface PanelRawData {
  Data: Array<Object>;
  HasNextPage: boolean;
  Aggregates: IAggregatesDataDashboard;
  HasOthersColumn: boolean;
}

export interface IAggregatesDataDashboard {
  GrandTotals: Obj;
}

export interface IRequestPanelDataSuccessPayload {
  response: IPanelDataResponse;
  panelIds: Array<string>;
  panelStatusCode?: statusCodes;
}

export interface PanelDataFetchCriteria {
  SourceType: PanelSourceTypes;
  SourceId: string;
  Filters: any;
  PageSize: number;
  Url: string;
  Target?:Array<string>;
  FiltersMetadata?:ObjModel.Obj;
  FeatureContext?: FeatureContext
}

export interface IPanelPayloads {
  Target: Array<string>;
  Source: PanelDataFetchCriteria;
  MappingFilters: Object;
  isFiltered: boolean;
  FiltersApplied: string[];
  FiltersMetaData?:ObjModel.Obj;
}

export interface IFiltersOnchange {
  FilterID: string;
  FilterValue: any;
}

export interface SaveTempDrilldownCriteria {
  SourceId: string;
  DataSourceId: string;
  DataSourceType: PanelSourceTypes;
  Filters: any;
  SelectedMeasure: string;
  SelectedRow: Array<SelectedRow>;
  MeasureFieldAlias?: string;
  DrilldownOnRowTotal?: boolean;
  FiltersMetadata?: ReportResponseModel.IFiltersMetaData;
}

export interface ISaveTempDrilldownSuccessPayload {
  panelId: string;
  ddStateId: string;
}

export enum DashboardActionTypes {
  CopyEmbedURL = 'Copy_Embed_URL',
}

export interface IDashboardData {
  Raw: PanelRawData;
  Visualization: IResponseVisualizationBuilder;
  loadingState: LoadingStatus;
  saveTempViewLoadingStatus: LoadingStatus;
  saveTempDrilldownLoadingStatus: LoadingStatus;
  ddStateId: string;
  panelStatusCode: statusCodes;
}

// Dashboard Builder Interface
export interface IDashboardBuilder {
  dashboardBuilderInfo: IDashboardBuilderInfo;
  requestProcessing: IRequestProcessing;
  assortedInfo: IAssortedDashboardInfo;
  selectedReportWidget: IReportWidget;
  embedURLSettings: IEmbedURLSettings;
}

export interface IEmbedURLSettings {
  title?: string;
  decodedUrl: string;
  source: ClickSource;
  sourceUrl: string;
  isUrlError: boolean;
  isMailMergeError: boolean;
}

export interface IReportWidget {
  rawReportConfig?: IReportConfig;
  reportData?: IPanelDataResponse;
  mappedMetaFields?: object;
  widgetSettings?: IWidgetSettings;
}

export interface IDashboardRequestProcessing {
  [DashboardAction.DELETE_DASHBOARD]: APIRequestStatus;
  [DashboardAction.GET_FILTERS_LIST_REQUEST]: APIRequestStatus;
  [DashboardAction.REQUEST_DASHBOARDS_LIST]:APIRequestStatus;
  [DashboardAction.SAVE_TEMP_VIEW_REQUEST]:APIRequestStatus;
  [DashboardAction.SAVE_TEMP_DRILLDOWN_REQUEST]:APIRequestStatus;
  [DashboardAction.SET_PERSONALIZED_DASHBOARD_REQUEST]:APIRequestStatus;
  [DashboardAction.DASHBOARD_ACCESS_CONTROL_REQUEST]: APIRequestStatus;
  [DashboardAction.REQUEST_DASHBOARD_CONFIG]:APIRequestStatus;
}
export interface IRequestProcessing {
  [DashboardAction.GET_REPORT_LIST_REQUEST]: APIRequestStatus;
  [DashboardAction.GET_REPORT_INFO]: APIRequestStatus;
  [DashboardAction.SAVE_DASHBOARD_CONFIG]: APIRequestStatus;
  [DashboardAction.GET_SELECTED_REPORT_WIDGET_INFO]: APIRequestStatus;
  [DashboardAction.REQUEST_DASHBOARD_CONFIG]: APIRequestStatus;
  [DashboardAction.VALIDATE_EMBED_URL]: APIRequestStatus;
  [DashboardAction.FILTER_DATA_LOAD_REQUEST]: ObjGeneric<APIRequestStatus>;
  [DashboardAction.GET_WIDGET_REPORT_DATA_REQUEST]:APIRequestStatus;
}

export interface IDashboardBuilderInfo {
  reportList: Array<IReportSection>;
  reportConfig: IReportConfig;
}

export interface IAssortedDashboardInfo {
  reportSearchKey: string;
  isFormDisabled: boolean;
  dashboardId: string;
  context: DashboardContext;
  addedWidget: string;
  widgetEditInfo: IWidgetEditInfo;
}

export interface IWidgetEditInfo{
  id: string;
  widgetDetails?: ObjModel.Obj;
}
export const defaultDashboardConfig: IDashboardConfig = {
  Id: '',
  TitleSection: {
    Title: '',
    Description: '',
  },
  Type: ReportType.Custom,
  Panels: [],
  Filters: [],
  Visualization: {
    IsDraggable: true,
    IsResizable: true,
    Margin: [20, 20],
    RowHeight: defaultRowHeight,
    CompactType: CompactTypes.Vertical,
  },
  IsHome: false,
  IsPersonalized: false,
  IsPinned: false,
};

export enum DashboardContext {
  Empty = '',
  Dashboard = 'Dashboard',
  DashboardBuilder = 'DashboardBuilder',
}

export interface IWidgetSettings {
  Type?: VisualizationTypes;
  Title?: string;
  PageSize?: number;
  attributes?: IChartAttributes;
  uniquePanelId?: any; // while adding to dashboard config we use this
  Columns?: Array<string |IWidgetColumnInfo>;
  SelectedReportId?: string;
  SelectedMeasures?: Array< string | IWidgetColumnInfo>;
}

export interface IDashboardPanelPayload {
  reportConfig: IReportConfig;
  widgetSettings: IWidgetSettings;
  isPanelEdit?: boolean;
}

export interface IReportMappedFilters {
  [key: string]: IFilterConfig[]
}

export interface IFilterListResponse {
  ReportFilters: {
    [key: string]: {
      Filters: IFilterConfig[],
      PartitionColumnIds:string[],
    }
  }
}

export interface IReportMappedColumns {
[key: string]: {
  isPartitioned: boolean,
  partitionFilterIds: string[],
}
}

export enum HomeDashboardPosition {
  CenterAligned = -170,
  LeftAligned = -290
}

export interface IBatchRequestPayload{
  target: string[],
  status: statusCodes
}

export interface IWidgetColumnInfo {
    Id: string,
    Name: string,
}
