import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import DOMPurify from 'dompurify';

import {
  ColumnModes,
  DimensionMode,
  DimensionNoValue,
  DimensionStatus,
  draggableItemType,
  ExportStatus,
  FieldEntitiesType,
  GroupedSubTotalsSeperator,
  MAX_DESC_CHARACTER_LIMIT,
  MAX_NAME_CHARACTER_LIMIT,
  MIN_CHARACTER_LIMIT,
  PreDefinedFieldEntityType,
  ProductLinesTypes,
  QueryParamNames,
  SubTotalsSeperator,
  ToolbarDisplayMenuItems,
  ToolbarMenuItemTypes,
  ZeroAnchorStatus,
} from 'core/constants/report';
import {
  FilterModel,
  GridModel, ObjModel, ReportReduxModel, ReportResponseModel, ReportToolbarModel,
} from 'core/models';
import FilterIcon from 'components/common/svg/Filter/filter.svg';
import ZeroValuesIcon from 'components/common/svg/ZeroValues/zero-values.svg';
import { displayName, isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import LeadIcon from 'components/common/svg/Lead/lead.svg';
import UserIcon from 'components/common/svg/User/User.svg';
import ActivityIcon from 'components/common/svg/Activity/activity.svg';
import TaskIcon from 'components/common/svg/Task/task.svg';
import { restrictedCharsForInput } from 'components/feature/Report/ReportSidebar/common/constants';
import FailedIcon from 'components/common/svg/Failed/failed.svg';
import SuccessIcon from 'components/common/svg/Success/success.svg';
import LoadingComponent from 'components/common/LoadingSpinner/loading-component';
import PreDefinedIcon from 'components/common/svg/PreDefined/pre-defined.svg';
import OpportunityIcon from 'components/common/svg/Opportunity/opportunity.svg';
import ChartsIcon from 'components/common/svg/ChartIconSidebar/chart-icon-sidebar.svg';
import { ChartAttributesKey, VisualizationTypes } from 'core/constants/visualizations';
import { IChartAttributes, IDimension } from 'core/models/report-redux';
import {
  IAttributesOptions,
  IChartAttributesKeys,
  IChartLabels,
} from 'components/feature/Report/ReportSidebar/ChartsVisualization/chart-visualization.type';
import { IColumn, IColumnDimension, IFilterConfig } from 'core/models/report-response';
import {
  IBuilder,
  IBuilderMeasure,
  IPaging,
  IReportBuilderColumn,
  IReportConfig,
  ISorting,
  ReportSourceInfo,
  ReportType,
  SortingOrderTypes,
} from 'core/models/report-builder/report-builder';
import { columnSortMap, ISortingRanked, makeColumnSortMap } from 'core/utils/sorting';
import {
  AccountsActivityEntityIcon,
  AccountsEntityIcon, CustomExpressionIcon, DataSettingHighlightedIcon, PinnedIconNew, TicketEntity,
  WebContentEntityIcon,
} from 'components/common/svg';
import { IPanelVisualisation } from 'core/models/dashboard';
import { PendoClassNames } from 'components/feature/Report/ReportSidebar/common/pendoClassNames';
import useLocalize from 'components/common/Localization/useLocalize.hook';
import { drilldownColumnDelimiter, FeatureContext } from 'core/constants/common';
import { FilterType } from 'core/constants/filter';
import { ReportFetchCriteria, SelectedRow } from 'services/report.service';
import { IReportState } from 'redux-v2/report/report-store.state';
import { getColumnsForActiveDimensions, getUpdatedMeasures, removeBuilderPropForMeasure } from 'redux-v2/report/report-store.utils';
import {
  getFilterMetaDataInfo, getSavePayloadBuilderDimensions, getSavePayloadBuilderFilters, getSavePayloadDimensionColumns, getSavePayloadMeasureColumns, getSavePayloadBuilderMeasures,
} from 'redux-v2/report-builder/report-builder-store.utils';
import TicketActivityEntity from 'components/common/svg/TicketActivityEntity/ticket-activity-entity.svg';
import { IColumnConfig } from 'core/models/grid';
import { usersPrefix } from 'core/constants/report-expressions';
import { DrilldownConfigMapping } from 'redux-v2/report-builder/report-builder-store.state';
import SalesCloudIcon from 'components/common/svg/SalesCloud/sales-cloud.svg';
import ConverseIcon from 'components/common/svg/Converse/converse.svg';
import LandingPageProIcon from 'components/common/svg/LandingPagePro/landing-page-pro.svg';
import ServiceCloudIcon from 'components/common/svg/ServiceCloud/service-cloud.svg';
import { IExpression, isAgg } from 'core/models/report-expressions';
import LeadSystemAuditIcon from 'components/common/svg/LeadSystemAudit/lead-system-audit-icon.svg';
import { getColumnsProperties } from './report-builder.util';
import { FilledReportPinnedIcon } from './custom-icons';
import { globalLogger } from './GlobalLogger';
import { clearPrefix } from './common.utility';
import { findUnderlyingColumn } from './report-expressions';

export const ReportToolbarMenuOptions = [
  {
    id: ToolbarMenuItemTypes.Filters,
    name: ToolbarMenuItemTypes.Filters,
    icon: FilterIcon,
    panelIcon: FilterIcon,
    desc: 'cmn.filterDesc',
    className: PendoClassNames.Filters,
    displayName: ToolbarDisplayMenuItems.Filters,
  },
  {
    id: ToolbarMenuItemTypes.Setting,
    name: ToolbarMenuItemTypes.Setting,
    icon: DataSettingHighlightedIcon,
    panelIcon: DataSettingHighlightedIcon,
    desc: 'cmn.settingsDesc',
    className: PendoClassNames.Setting,
    displayName: ToolbarDisplayMenuItems.Setting,
  },
  {
    id: ToolbarMenuItemTypes.Charts,
    name: ToolbarMenuItemTypes.Charts,
    icon: ChartsIcon,
    panelIcon: ChartsIcon,
    desc: 'cmn.visualiseDesc',
    className: PendoClassNames.ChartSideBar,
    displayName: ToolbarDisplayMenuItems.Charts,
  },
  {
    id: ToolbarMenuItemTypes.ZeroValues,
    name: ToolbarMenuItemTypes.ZeroValues,
    icon: ZeroValuesIcon,
    panelIcon: ZeroValuesIcon,
    desc: 'cmn.zeroValuesDesc',
    className: PendoClassNames.ZeroValues,
    displayName: ToolbarDisplayMenuItems.ZeroValues,
  },
  {
    id: ToolbarMenuItemTypes.PinnedReports,
    name: ToolbarMenuItemTypes.PinnedReports,
    icon: PinnedIconNew,
    panelIcon: FilledReportPinnedIcon,
    desc: 'cmn.pinnedRepDesc',
    className: PendoClassNames.PinnedReports,
    displayName: ToolbarDisplayMenuItems.PinnedReports,
  },
] as Array<ReportToolbarModel.IToolbarMenuItem>;

export const getTextTooltipContentForDimension = (dimension: ReportReduxModel.IDimension | IColumn) => {
  if (dimension) {
    const text = displayName(dimension);
    if (dimension.BuilderConfig && dimension.BuilderConfig.IsDynamicField) {
      return `${text} (${dimension.BuilderConfig.SchemaName})`;
    }
    return text;
  }
  return '';
};

export const getFieldIdentifier = (
  field: ReportResponseModel.IColumn | ReportReduxModel.IDimension,
) => {
  if (field) {
    if (isMeasure(field as IColumn) && field.BuilderConfig?.Entity === FieldEntitiesType.Custom) {
      return field.BuilderConfig.uniqueKey?.toString();
    }
    if (isMeasureDynamic(field as IColumn)) {
      return field.Name + field?.BuilderConfig?.Alias || '';
    }
    if (isDimensionCustom(field as IColumn)) {
      return field.Name;
    }
    return getEntityKey(field);
  }
  return null;
};

export const getFieldName = (field: ReportResponseModel.IColumn | ReportReduxModel.IDimension) => {
  if (field?.BuilderConfig?.Entity === FieldEntitiesType.Custom || isMeasureDynamic(field as IColumn)) {
    // reuse getFieldIdentifier code for these conditions
    return getFieldIdentifier(field);
  }
  return field.Name;
};

export const getFieldDisplayName = (field: ReportResponseModel.IColumn, isDrilldown: boolean = false) => {
  let name;
  if (field) {
    if (field.BuilderConfig && field.BuilderConfig.IsDynamicField) {
      return `${field.BuilderConfig.DisplayName}`;
    }
    name = field.Props.ReferTo || field.Name || '';
  }
  if (isDrilldown) {
    name = clearPrefix(name, drilldownColumnDelimiter);
  }
  return name;
};

export const getTextTooltipContentForField = (field: ReportResponseModel.IColumn) => {
  if (field) {
    const text = getFieldDisplayName(field);
    if (field.BuilderConfig && field.BuilderConfig.IsDynamicField) {
      return `${text} (${field.BuilderConfig.SchemaName})`;
    }
    return text;
  }
  return '';
};

export const isMeasure = (column: IColumn) => !!column?.Props?.Measure?.IsMeasure;
export const isMeasureAndApplied = (column: IColumn) => isMeasure(column) && column.Props.Mode !== ColumnModes.Hidden && column.Props.Mode !== ColumnModes.NotApplied;
export const isMeasureDynamic = (column: IColumn) => !!column?.Props?.Measure?.Expression;
export const isMeasurePredefined = (column: IColumn) => isMeasure(column) && !isMeasureDynamic(column) && !isMeasureCustom(column);
export const isMeasureCustom = (column: IColumn) => isMeasure(column) && column.BuilderConfig?.Entity === FieldEntitiesType.Custom;
export const isDimensionCustom = (column: IColumn) => column?.Props?.Dimension && column?.BuilderConfig?.Entity === FieldEntitiesType.Custom;
export const getAppliedMeasures = (columns: IColumn[]) => columns?.filter((c) => isMeasureAndApplied(c));

export const isMaxMeasuresApplied = (columns: IColumn[], maxAppliedMeasures?: number) => columns?.length === maxAppliedMeasures;
export const isWrongInput = (inputText: string) => !(DOMPurify.sanitize(inputText) === inputText) || restrictedCharsForInput.some((char: string) => (!!inputText.includes(char)));
export const isInputLimitReached = (inputText : string, limit: number) => inputText.length >= limit;

export const isDimensionApplied = (dim: IColumnDimension) => !!dim && dim.Applied !== DimensionStatus.NotApplied;
export const isDimensionRow = (dim: IColumnDimension) => dim?.DimensionMode === DimensionMode.RowGroup || dim?.DimensionMode === DimensionMode.Both;
export const isDimensionColumn = (dim: IColumnDimension) => dim?.DimensionMode === DimensionMode.ColumnGroup || dim?.DimensionMode === DimensionMode.Both;
// For Dynamic having no metafield we pass the Name
export const referredName = (col: IColumn | IDimension, columns: Array<IColumn | IDimension>) => (((col as IDimension).ReferTo && (columns as IDimension[]).find((dim: IDimension) => col.Props.ReferTo === dim.Id)?.Name) || col.Props?.ReferTo || col.Name);

export const isPieOrDonutChart = (type: VisualizationTypes) => type === VisualizationTypes.PieChart || type === VisualizationTypes.DonutChart;
export const isReportJoined = (report: IReportConfig) => report?.Builder?.some((b) => !!b.Joins?.length);

/** Checks whether a dimension is additional/dynamic and not from the report config. */
export const isDimensionDynamic = (dim: IDimension | IColumn) => !!dim?.BuilderConfig?.IsDynamicField;
/** Generates a unique string for the given dimension composed of its join ID, alias, and/or name. */
export const makeDimensionKey = (dim: IDimension | IColumn) => `${dim?.BuilderConfig?.JoinID ? `${dim?.BuilderConfig?.JoinID}:` : ''}${dim?.Id ?? ''}`;
/** Compares two dimensions or columns. */
export const areDimensionsEqual = (dim1: IDimension | IColumn, dim2: IDimension | IColumn) => {
  const oneIsDynamic = isDimensionDynamic(dim1);
  const twoIsDynamic = isDimensionDynamic(dim2);
  if ((oneIsDynamic && !twoIsDynamic) || (!oneIsDynamic && twoIsDynamic)) return false;
  return getEntityKey(dim1) === getEntityKey(dim2);
};
// Check wheater a column is linked to any parent column
export const isColumnReferredCol = (column: ReportResponseModel.IColumn) => !isEmpty(column?.Props.ParentDimension);

/** Generates a unique string for the given dimension composed of its Entity, join ID, alias, and/or name. */
export const getEntityKey = (dim: IDimension | IColumn) => `${dim?.BuilderConfig?.Entity}:${makeDimensionKey(dim)}`;

export const isUserFieldSwitchAvl = (dim:IDimension) => dim.IsFieldSwitch;

export const isDefaultUserField = (dim:IDimension | IColumn, linkedFilter: IFilterConfig) => !!(!dim?.Props?.IsUserDynamicField && !dim?.Props?.IsAdditional && dim?.Props?.ReferTo && linkedFilter?.Alias === dim?.BuilderConfig?.SchemaName);

export const referredDimId = (col: IColumn | IDimension) => (col as IDimension)?.ReferTo || col?.Props?.ReferTo || col?.Id;

// generic search compares two string
// params query:- query string
// params searchField:- string to be compared
// returns true if searchField has contains matching query string
// return false if  query does not match and query is empty.
export const genericSearch = (query: string = '', searchField: string = '') => (
  query
    ? searchField.toString().toLowerCase().includes(query.toLowerCase())
    : true);

export const sortAdditionalFields = <T extends ReportReduxModel.IDimension | ReportResponseModel.IColumn>
  (dimensions: T[]) => sortBy(dimensions, (item) => {
    let name = item.Name;

    // if builder config is present, we chose displayname to sort. We trim and do lowercase to make it readable for user
    if (item.BuilderConfig && item.BuilderConfig.IsDynamicField) {
      name = item.BuilderConfig.DisplayName || '';
    }

    // for default columns, we just show it by alias configured in config
    return [name.toLowerCase().replace(/\s/g, '')];
  });

export const FieldEntities = {
  [PreDefinedFieldEntityType]: {
    icon: PreDefinedIcon,
    color: 'var(--purpleColor)',
  },
  [FieldEntitiesType.Lead]: {
    icon: LeadIcon,
    color: 'var(--avtarColor)',
  },
  [FieldEntitiesType.Activity]: {
    icon: ActivityIcon,
    color: 'var(--expandListButtonHoverColor)',
  },
  [FieldEntitiesType.Opportunity]: {
    icon: OpportunityIcon,
    color: 'var(--opportunityColor)',
  },
  [FieldEntitiesType.Task]: {
    icon: TaskIcon,
    color: 'var(--taskColor)',
  },
  [FieldEntitiesType.User]: {
    icon: UserIcon,
    color: 'var(--userColor)',
  },
  [FieldEntitiesType.Custom]: {
    icon: CustomExpressionIcon,
    color: 'var(--customEntiyColor)',
  },
  [FieldEntitiesType.Ticket]: {
    icon: TicketEntity,
    color: 'var(--ticketEntityColor)',
  },
  [FieldEntitiesType.TicketActivity]: {
    icon: TicketActivityEntity,
    color: 'var(--ticketActivityEntity)',
  },
  [FieldEntitiesType.Accounts]: {
    icon: AccountsEntityIcon,
    color: 'var(--accountsEntity)',
  },
  [FieldEntitiesType.AccountsActivity]: {
    icon: AccountsActivityEntityIcon,
    color: 'var(--accountsActivityEntity)',
  },
  [FieldEntitiesType.WebContent]: {
    icon: WebContentEntityIcon,
    color: 'var(--webContentEntity)',
  },
  [FieldEntitiesType.LeadSystemAudit]: {
    icon: LeadSystemAuditIcon,
    color: 'var(--avtarColor)',
  },
} as ObjModel.ObjGeneric<IFieldEntity>;

export const ProductLines = {
  [ProductLinesTypes.SalesCloud]: {
    icon: SalesCloudIcon,
    color: 'var(--secondaryColor)',
  },
  [ProductLinesTypes.Converse]: {
    icon: ConverseIcon,
    color: 'var(--secondaryColor)',
  },
  [ProductLinesTypes.LandingPagePro]: {
    icon: LandingPageProIcon,
    color: 'var(--secondaryColor)',
  },
  [ProductLinesTypes.ServiceCloud]: {
    icon: ServiceCloudIcon,
    color: 'var(--secondaryColor)',
  },
} as ObjModel.ObjGeneric<IFieldEntity>;

export interface IFieldEntity {
  icon: React.ElementType;
  color: string;
}

export const NameSpace = {
  users: 'users',
};

export const ExportStatusIcons = {
  [ExportStatus.InProgress]: {
    icon: LoadingComponent,
    expiryText: 'inProgress',
  },
  [ExportStatus.InQueue]: {
    icon: LoadingComponent,
    expiryText: 'inProgress',
  },
  [ExportStatus.Success]: {
    icon: SuccessIcon,
    expiryText: 'expiresIn',
  },
  [ExportStatus.Failed]: {
    icon: FailedIcon,
    expiryText: 'failed',
  },
} as ObjModel.ObjGeneric<IExportStatusIcon>;

export interface IExportStatusIcon {
  icon: React.ElementType
  expiryText: string
}

export const errorHandler = (error: Error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  // TODO: logging to server or adding the logs to index Db code
};

export const getTextTooltipContentForMeasure = (measure: IColumn) => {
  if (measure) {
    const text = displayName(measure);
    if (measure?.Props?.Measure?.IsMeasure && measure?.BuilderConfig) {
      return `${text} ${measure?.BuilderConfig?.SchemaName ? `(${measure?.BuilderConfig?.SchemaName})` : ''}`;
    }
    return text;
  }
  return '';
};

const getRowValue = (data: ObjModel.Obj, column: GridModel.IColumnConfig) => (!data[column?.props?.ReferTo] && !data[column?.colId] ? DimensionNoValue : data[column?.colId]);

const getRowData = (columnsWithSubTotals: IColumnConfig[], data: ObjModel.Obj) => (isValidArrayAndNotEmpty(columnsWithSubTotals) ? columnsWithSubTotals.map((column) => {
  // For metafields if both parent and reffered value is empty we set it as NoValue else we take parent value it self
  if (column?.props?.ReferTo) {
    return ({
      Name: column.colId,
      Value: getRowValue(data, column),
    });
  }
  // Check if it is a dimension set the No Value for falsy Value and in case of drilldown columns bypass this logic
  if (!column.props?.isDrilldownColumn && (column.props?.ReferTo || column.props?.Dimension || column.props?.ParentDimension)) {
    return { Name: column.colId, Value: !data[column.colId] ? DimensionNoValue : data[column.colId] };
  }
  return ({
    Name: column.colId,
    Value: data[column.colId],
  });
}) : []);

export const getRawDataConfig = (activeDimensions: Array<ObjModel.Obj>, reportStore: IReportState): {
  rowData: Array<ObjModel.Obj>,
  columns: Array<GridModel.IColumnConfig>,
} => {
  const columns = getColumnsProperties(
    reportStore.columns,
    reportStore.reportConfig.AllowDrilldownOnMaskedDimensions,
  );
  const columnsWithSubTotals = [...columns];
  const appliedRowDims = isValidArrayAndNotEmpty(reportStore?.columns) ? reportStore?.columns.filter((col) => col.Props.Dimension && col.Props.Dimension.Applied === DimensionStatus.Applied && col.Props.Dimension.DimensionMode === DimensionMode.RowGroup) : [];

  columns?.forEach((item) => {
    if (!!item?.props?.Measure?.IsMeasure && item.props.Mode !== ColumnModes.Hidden && item.props.Mode !== ColumnModes.NotApplied) {
      columnsWithSubTotals.push({ ...item, colId: `${item.colId}${SubTotalsSeperator}` });
      if (appliedRowDims?.length > 2) columnsWithSubTotals.push({ ...item, colId: `${item.colId}${GroupedSubTotalsSeperator}` });
    }
  });
  const rowData: Array<ObjModel.Obj> = [];
  if (reportStore.reportData?.Raw?.Data && reportStore.reportData?.Raw?.Data.length) {
    const reportData = reportStore?.reportData?.Raw?.Data;
    for (let i = 0; i < reportData.length; i += 1) {
      const data = reportData[i];
      rowData.push({
        Fields: getRowData(columnsWithSubTotals, data),
      });
    }
  }

  return { rowData, columns };
};
export const getFilterDisplayName = (filter: ReportResponseModel.IFilterConfig) => filter?.Label || '';

export const getTextTooltipContentForFilter = (filter: ReportResponseModel.IFilterConfig) => {
  if (filter) {
    const text = getFilterDisplayName(filter);
    if (filter.IsDynamic) {
      return `${text} (${filter.SchemaName})`;
    }
    return text;
  }
  return '';
};

export const getDashboardChartAttributes = (type: VisualizationTypes, chartLabels: IChartLabels, selectedPage: String, key: IChartAttributesKeys) => {
  const dashboardChartAttributes : ObjModel.ObjGeneric<IAttributesOptions> = getChartAttributes(type, chartLabels, selectedPage);
  const keys = Object.keys(dashboardChartAttributes);
  keys?.forEach((item) => {
    dashboardChartAttributes[item as VisualizationTypes].Attributes = dashboardChartAttributes[item as VisualizationTypes]?.Attributes?.filter((attribute) => attribute?.key !== key);
  });
  return dashboardChartAttributes;
};

export const getChartAttributes = (type: VisualizationTypes, chartLabels: IChartLabels, selectedPage: String): ObjModel.ObjGeneric<IAttributesOptions> => (
  {
    Table: {
      Type: type,
      Attributes: [
        {
          key: 'showSubTotals',
          label: 'chartAttr.showSubTotals',
          PendoClassNames: `${PendoClassNames.ShowSubTotals}-Column-${selectedPage}`,
          hide: false,
        },
        {
          key: 'showSummary',
          label: 'chartAttr.showSummary',
          PendoClassNames: `${PendoClassNames.ShowSummary}-Column-${selectedPage}`,
          hide: false,
        },
      ],
    },
    Column: {
      Type: type,
      Attributes: [
        {
          key: 'showDataLabels',
          label: 'chartAttr.showLabel',
          PendoClassNames: `${PendoClassNames.ShowDataLabels}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'showSharedTooltip',
          label: 'chartAttr.showSharedTooltip',
          PendoClassNames: `${PendoClassNames.SharedTooltip}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'hideLegend',
          label: 'chartAttr.hideLegend',
          PendoClassNames: `${PendoClassNames.HideLegend}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'stacked',
          label: `chartAttr.${chartLabels[type as keyof IChartLabels]}`,
          PendoClassNames: `${PendoClassNames.Stack}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'fullStacked',
          label: 'chartAttr.stackedPercent',
          PendoClassNames: `${PendoClassNames.HundredPercentStack}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'showSubTotals',
          label: 'chartAttr.showSubTotals',
          PendoClassNames: `${PendoClassNames.ShowSubTotals}-Column-${selectedPage}`,
          hide: true,
        },
        {
          key: 'showSummary',
          label: 'chartAttr.showSummary',
          PendoClassNames: `${PendoClassNames.ShowSummary}-Column-${selectedPage}`,
          hide: true,
        },
      ],
    },
    Line: {
      Type: type,
      Attributes: [
        {
          key: 'showDataLabels',
          label: 'chartAttr.showLabel',
          PendoClassNames: `${PendoClassNames.ShowDataLabels}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'showSeriesLabel',
          label: 'chartAttr.showSeriesLabel',
          PendoClassNames: `${PendoClassNames.ShowSeriesLabels}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'showSharedTooltip',
          label: 'chartAttr.showSharedTooltip',
          PendoClassNames: `${PendoClassNames.SharedTooltip}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'hideLegend',
          label: 'chartAttr.hideLegend',
          PendoClassNames: `${PendoClassNames.HideLegend}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'stacked',
          label: `chartAttr.${chartLabels[type as keyof IChartLabels]}`,
          PendoClassNames: `${PendoClassNames.Stack}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'fullStacked',
          label: 'chartAttr.stackedPercent',
          PendoClassNames: `${PendoClassNames.HundredPercentStack}-Column-${selectedPage}`,
          hide: false,

        },
        {
          key: 'showSubTotals',
          label: 'chartAttr.showSubTotals',
          PendoClassNames: `${PendoClassNames.ShowSubTotals}-Column-${selectedPage}`,
          hide: true,
        },
        {
          key: 'showSummary',
          label: 'chartAttr.showSummary',
          PendoClassNames: `${PendoClassNames.ShowSummary}-Column-${selectedPage}`,
          hide: true,
        },
      ],
    },
    Pie: {
      Type: type,
      Attributes: [
        {
          key: 'showCategoryName',
          label: 'chartAttr.showCategoryName',
          PendoClassNames: `${PendoClassNames.ShowCategoryName}-Column-${selectedPage}`,
          hide: false,
        },
        {
          key: 'showDataLabels',
          label: 'chartAttr.showLabel',
          PendoClassNames: `${PendoClassNames.ShowDataLabels}-Pie-${selectedPage}`,
          hide: false,
        },
        {
          key: 'showPercentage',
          label: 'chartAttr.showPer',
          PendoClassNames: `${PendoClassNames.ChartPercent}-Pie-${selectedPage}`,
          hide: false,
        },
        {
          key: 'hideLegend',
          label: 'chartAttr.hideLegend',
          PendoClassNames: `${PendoClassNames.HideLegend}-Column-${selectedPage}`,
          hide: false,
        },
        {
          key: 'showSubTotals',
          label: 'chartAttr.showSubTotals',
          PendoClassNames: `${PendoClassNames.ShowSubTotals}-Column-${selectedPage}`,
          hide: true,
        },
        {
          key: 'showSummary',
          label: 'chartAttr.showSummary',
          PendoClassNames: `${PendoClassNames.ShowSummary}-Column-${selectedPage}`,
          hide: true,
        },
      ],
    },
  }
);
export const validateAndAppendCountToName = (measure: IColumn, allMeasures: Array<IColumn>) => {
  const duplicateMeasureDetails = getDuplicateMeasuresDetails(measure, allMeasures);
  let updatedName = duplicateMeasureDetails.updatedName;
  const { sameMeasureNames, sameMeasureCount } = duplicateMeasureDetails;
  if (sameMeasureCount > 0) {
    const counts: number[] = [];
    let largestNum: number = 0;
    sameMeasureNames.forEach((item: IColumn) => counts.push(parseInt(item?.Name?.split(`${updatedName}_`)[1], 10) || 0));
    counts.forEach((element: number) => {
      if (largestNum <= element) {
        largestNum = element;
      }
    });
    const counter = largestNum + 1;
    updatedName = `${updatedName}_${counter}`;
    return updatedName;
  }
  return updatedName;
};

export const getDuplicateMeasuresDetails = (measure: IColumn, allMeasures: Array<IColumn>) => {
  const updatedName = `Sum(${measure.Name})`;
  const sameMeasureNames = allMeasures.filter((item: IColumn) => item.Name.startsWith(updatedName));
  const sameMeasureCount = sameMeasureNames?.length || 0;
  return { updatedName, sameMeasureNames, sameMeasureCount };
};

/** Sorts the dimensions by their applied status. */
export const reOrderDimensions = (appliedDimensions: IDimension[]) => sortBy(appliedDimensions, (dim) => {
  if (dim.DimensionMode !== DimensionMode.ColumnGroup && dim.Applied !== DimensionStatus.NotApplied) {
    return 0; // row grouped dimensions come first
  }
  if (dim.DimensionMode !== DimensionMode.RowGroup && dim.Applied !== DimensionStatus.NotApplied) {
    return 1; // then column grouped dimensions
  }
  return 2; // finally un-applied and any leftover dimensions
});

export const reOrderMeasures = (measures: IColumn[]): IColumn[] => sortBy(
  measures,
  (m) => (isMeasureAndApplied(m) ? 0 : 1),
);

export const columnsToDimensions = (columns: IColumn[]): IDimension[] => {
  // logic to remove duplicate columns which has same Id's
  const seen = new Set();
  return columns
    ?.map((item) => {
      if (item.Props.Dimension) {
        const key = item.Id;
        if (!seen.has(key)) {
          seen.add(key);
          const dimension = {
            Name: item.Name,
            ReferTo: item.Props.ReferTo,
            ...item.Props.Dimension,
            BuilderConfig: item.BuilderConfig,
            IsMasked: item.Props.IsMasked,
            Mode: item.Props?.Mode,
            IsUserDynamicField: item?.Props?.IsUserDynamicField,
            Props: item?.Props,
            Id: item.Id,
          };
          if (dimension.ZeroAnchor !== ZeroAnchorStatus.Disabled) {
            dimension.ZeroAnchor = ZeroAnchorStatus.Enabled; // un-apply zero values by default
          }
          return dimension;
        }
      }
      return null;
    })
    .filter((item) => !!item);
};

export const sortColumns = (
  sortState: ISorting[], appliedDimensions: IDimension[], appliedMeasures: IColumn[],
  columnType: draggableItemType,
  columnName: string,
  order: SortingOrderTypes | null, // null implies removal of sort
  multiSort: boolean,
  Id: string,
  drilldownFields?: IColumn[],
  isDrilldownPage : boolean = false, // defaults to false, tells if a page is drilldown report or not
): [ISorting[], boolean] => {
  if (!columnExists(Id, columnType, appliedDimensions, appliedMeasures, drilldownFields)) { return [sortState, false]; }
  const newSortState = sortState ? [...sortState] : [];
  const sortIndex = newSortState.findIndex((s) => s.Id === Id);
  const newOrder = disableInactiveSorting(newSortState, appliedDimensions, columnType, order, Id);

  if (!(newOrder || sortIndex >= 0)) { // removing an un-applied sort is not possible
    return [newSortState, false];
  }
  const deleteStart = deleteNonMandatory(newSortState, appliedDimensions, multiSort, isDrilldownPage); // delete non-mandatory sorts if not multi sorting

  if (newOrder && sortIndex >= 0 && sortIndex < deleteStart && sortIndex < newSortState?.length) { // replaces applied or non-deleted sort objects
    newSortState[sortIndex].Field = columnName;
    newSortState[sortIndex].Direction = newOrder;
    newSortState[sortIndex].Id = Id;
  } else if (!newOrder) { // removes the sort object
    newSortState.splice(sortIndex, 1);
  } else if (sortIndex < 0 || sortIndex >= deleteStart) { // pushes newly applied or just-deleted sort objects to the list
    newSortState.push({
      Field: columnName,
      Direction: newOrder,
      Id,
    });
  }

  return [rebuildSortState(newSortState, appliedDimensions, appliedMeasures, drilldownFields || [], !multiSort, isDrilldownPage), true];
};

export const rebuildSortState = (sortState: ISorting[], appliedDimensions: IDimension[], appliedMeasures: IColumn[], drilldownFields:IColumn[], noMultiSort?: boolean,
  isDrilldownPage : boolean = false): ISorting[] => {
  const sortLookup = makeColumnSortMap(sortState); // sortLookup is a map of sort Field -> sort object with rank
  const makeDefault = (name: string, columnId: string) => makeDefaultSort(name, sortLookup, columnId); // creates a new sort object with rank -1, only if it does not exist

  const appliedColDimensions = appliedDimensions?.filter((dim) => isDimensionApplied(dim) && isDimensionColumn(dim));
  const appliedRowDimensions = appliedDimensions?.filter((dim) => isDimensionApplied(dim) && isDimensionRow(dim));
  const appliedDrilldownFields = isValidArrayAndNotEmpty(drilldownFields) ? drilldownFields.filter((col) => col.Props.Dimension.Applied === DimensionStatus.Applied) : [];
  const hasPivot = appliedColDimensions?.length > 0;

  const colDimSorts = isDrilldownPage ? [] : appliedColDimensions
    ?.map((dim): ISortingRanked => makeDefault(referredName(dim, appliedDimensions), referredDimId(dim)));
  const rowDimSorts = isDrilldownPage ? [] : appliedRowDimensions
    ?.slice(0, -1) // last row grouping is not a mandatory sort, if any
    ?.map((dim): ISortingRanked => makeDefault(referredName(dim, appliedDimensions), referredDimId(dim)));
  const measureSorts = hasPivot || isDrilldownPage ? [] : appliedMeasures // measures cannot be sorted in pivot mode or in drilldown mode as column is not present
    // use measures only if they were previously applied, since measures are not mandatory
    ?.filter((measure) => isMeasureAndApplied(measure) && !!sortLookup[measure.Id])
    ?.map((measure): ISortingRanked => makeDefault(measure.Name, measure.Id));
  const drilldownSorts = isValidArrayAndNotEmpty(appliedDrilldownFields) ? appliedDrilldownFields.filter((column) => !!sortLookup[column.Id]).map((col) => makeDefault(col.Name, referredDimId(col))) : [];
  const lastRowDimSort = isDrilldownPage ? [] : appliedRowDimensions // removing group sorts for drilldown page as well
    ?.slice(-1)
    // use last row grouping only if it was previously applied (since it is not mandatory)
    // or if sort list is going to be empty (apart from column groupings, since at least one other sort must be present)
    ?.filter((dim) => !!sortLookup[referredDimId(dim)] || (rowDimSorts.length === 0 && measureSorts.length === 0))
    ?.map((dim): ISortingRanked => makeDefault(referredName(dim, appliedRowDimensions), referredDimId(dim)));

  const restSorts = sortBy( // sort last row grouping and measures together by their previous ranks
    [...lastRowDimSort, ...drilldownSorts, ...measureSorts],
    (sort: ISortingRanked) => (sort.rank === -1 ? sortState.length : sort.rank), // newly added sorts are pushed to the end
  )
    ?.slice(0, noMultiSort ? 1 : undefined); // take only the first item if not multi sorting, else take the whole array

  return [...colDimSorts, ...rowDimSorts, ...restSorts]
    ?.map((sort): ISorting => ({
      ...sort,
      rank: undefined,
    } as ISortingRanked)); // remove rank field to avoid confusion
};

const columnExists = (Id: string, columnType: draggableItemType, dimensions: IDimension[], measures: IColumn[], drilldownFields: IColumn[]): boolean => {
  let columns: (IDimension | IColumn)[] = [];
  if (columnType === draggableItemType.Measure) {
    columns = measures ?? [];
  } else if (columnType === draggableItemType.RowDim || columnType === draggableItemType.ColumnDim) {
    columns = dimensions ?? [];
  } else if (columnType === draggableItemType.DrilldownDim) {
    columns = drilldownFields;
  }
  if (isValidArrayAndNotEmpty(columns)) {
    return columns?.findIndex((col) => referredDimId(col) === Id) !== -1;
  }
  return false;
};

const disableInactiveSorting = (
  sortState: ISorting[], appliedDimensions: IDimension[],
  columnType: draggableItemType,
  order: SortingOrderTypes | null,
  Id: string,
): SortingOrderTypes | null => {
  if (!(order !== SortingOrderTypes.ASC && order !== SortingOrderTypes.DESC) || columnType === draggableItemType.DrilldownDim) return order;

  let disable = columnType === draggableItemType.ColumnDim; // column grouping sort cannot be disabled
  if (!disable) { // last sort of the list (apart from column grouping) should not be removed to avoid empty sort list
    const columnGroupings = appliedDimensions.filter((dim) => isDimensionApplied(dim) && isDimensionColumn(dim)).length;
    disable = sortState.length <= columnGroupings + 1 && sortState[sortState.length - 1]?.Id === Id;
  }
  if (disable) {
    return SortingOrderTypes.ASC;
  }
  return order;
};

const deleteNonMandatory = (sortState: ISorting[], appliedDimensions: IDimension[], multiSort: boolean, isDrilldownPage:boolean): number => {
  let deleteStart = sortState.length; // delete nothing if multi sort is enabled
  if (!multiSort) {
    deleteStart = appliedDimensions
      .filter((dim) => isDimensionApplied(dim) && (isDimensionRow(dim) || isDimensionColumn(dim)))
      .length - 1; // column groupings and row groupings minus 1 are mandatory
    deleteStart = deleteStart < 0 ? 0 : deleteStart;
    if (isDrilldownPage) { // if not multisort and is a drilldown page then remove the existing items from store state
      sortState.splice(0);
      return 0;
    }
    sortState.splice(deleteStart);
  }
  return deleteStart;
};

const makeDefaultSort = (name: string, sortLookup: columnSortMap, columnId: string): ISortingRanked => ({ // creates a new sort object with rank -1, only if it does not exist
  Field: name,
  Direction: SortingOrderTypes.ASC,
  Id: columnId,
  rank: -1, // used to push the newly added sort object to the end of the list
  ...sortLookup[columnId], // override above values with existing sort object, if any
});

export const getUpdatedReportConfig = (reportStore: IReportState, isSaveAs:boolean = false):IReportConfig => {
  const addedFilter = reportStore.filterConfig.filter(
    (config: IFilterConfig) => config.IsRemoved !== true,
  );
  let builderMeasures: IBuilderMeasure[] = [];
  const nameSpaceInfo = reportStore.savedReportConfig?.ConfigMetadata?.namespaceTypes;
  const updatedReportConfig:IReportConfig = {
    ...reportStore.savedReportConfig,
    Sorting: [...reportStore.sort],
    Paging: { ...reportStore.page },
    FiltersMetadata: getFilterMetaDataInfo(addedFilter, reportStore.appliedFilters),
    ConfigMetadata: undefined,
  };
  if (isSaveAs) {
    updatedReportConfig.ReportConfigId = '';
    updatedReportConfig.Details = {
      Title: reportStore.reportNameSaveAs,
      Description: reportStore.reportDescriptionSaveAs,
      Type: ReportType.Custom,
    };
    updatedReportConfig.SourceInfo = {
      Id: reportStore.reportConfig.ReportConfigId,
      Type: ReportSourceInfo.Report,
    };
  }

  updatedReportConfig.Visualization = getUpdateVisualization(updatedReportConfig.Details.Title, reportStore?.Visualization?.type,
    reportStore?.Visualization?.attributes, reportStore?.reportConfig.Visualization?.DrillDownConfigMapping, reportStore?.reportConfig.Visualization?.DrilldownConfigIdMapping, reportStore?.reportConfig?.Visualization?.DrillDownPagination);

  const isAugumented = reportStore.savedReportConfig?.Builder?.length > 1;
  updatedReportConfig.Builder = reportStore.savedReportConfig.Builder.map(
    (builderItem: IBuilder) => {
      builderMeasures = [...builderMeasures, ...builderItem.Select.Measures];
      if (updatedReportConfig?.CalculatedColumns) {
        builderMeasures = builderMeasures.concat(updatedReportConfig.CalculatedColumns).flat();
      }
      return ({
        ...builderItem,
        Select: {
          ...builderItem.Select,
          Dimensions: getSavePayloadBuilderDimensions(reportStore?.activeDimensions, [...builderItem?.Select?.Dimensions], reportStore?.filterConfig, nameSpaceInfo, builderItem.Namespace, isAugumented),
          Measures: getMeasuresByBuilderType(reportStore, builderItem),
        },
        Filter: getSavePayloadBuilderFilters(addedFilter, reportStore.appliedFilters, builderItem.Filter),
      });
    },
  );
  const updatedDimensions = [...reportStore.activeDimensions];
  reportStore.columns.forEach((item) => {
    if (!item?.Props?.Dimension && item?.Props?.ParentDimension) {
      updatedDimensions.push(convertColumnToDimension(item));
    }
  });
  updatedReportConfig.Columns = [...getSavePayloadDimensionColumns(updatedDimensions, reportStore?.filterConfig), ...getSavePayloadMeasureColumns(
    reportStore?.allMeasures,
    getDefaultMeasures(builderMeasures, reportStore.columns),
    reportStore.savedReportConfig,
  )];
  updatedReportConfig.Sorting = getUpdatedSortObjectForSave(updatedDimensions, reportStore.sort);

  globalLogger(`report store ${reportStore}`);
  globalLogger(`updateReportConfig ${updatedReportConfig}`);

  if (Object.prototype.hasOwnProperty.call(updatedReportConfig, 'FeatureContext') && !updatedReportConfig.FeatureContext) {
    updatedReportConfig.FeatureContext = FeatureContext.Sales;
  }
  return updatedReportConfig;
};

export const getDefaultMeasures = (builderMeasures: IBuilderMeasure[], configColumns: IReportBuilderColumn[]) => {
  const defaultMeasures : IReportBuilderColumn[] = [];

  configColumns.forEach((item) => {
    const builderMeasure = builderMeasures?.find((measure) => measure.Id === item.Id);
    if (builderMeasure) {
      // eslint-disable-next-line no-param-reassign
      item.Props.IsAdditional = builderMeasure.IsAdditional;
      defaultMeasures.push(item);
    }
  });

  return defaultMeasures;
};

const getMeasuresByBuilderType = (reportStore: IReportState, builder:IBuilder):Array<IBuilderMeasure> => {
  const appliedMeasures = reportStore.allMeasures.filter((item: IColumn) => isMeasureAndApplied(item));
  const buidlerMeasure = getSavePayloadBuilderMeasures(appliedMeasures, builder.Select.Measures);
  return buidlerMeasure;
};

export const getUpdateVisualization = (visualizatonDisplayName:string, type: VisualizationTypes, attributes: IChartAttributes, drilldownConfigMapping?: DrilldownConfigMapping, drilldownConfigIdMapping?: DrilldownConfigMapping, drilldownPagination?:IPaging):IPanelVisualisation => {
  const updatedVisualisation :IPanelVisualisation = {
    DisplayName: visualizatonDisplayName,
    Type: type,
    Builder: {
      Properties: {
        ...attributes,
      },
    },
    DrilldownConfigMapping: drilldownConfigMapping,
    DrilldownConfigIdMapping: drilldownConfigIdMapping,
    DrilldownPagination: drilldownPagination,
  };

  return updatedVisualisation;
};

export const removeQueryParamsDataInURL = (selectingFromSideBarOrRefresh?: boolean) => {
  const queryParams = new URLSearchParams(window.location.search);

  if (selectingFromSideBarOrRefresh) {
    queryParams.delete(QueryParamNames.SourceId);
    if (queryParams.get(QueryParamNames.DrilldownStateID)) {
      queryParams.delete(QueryParamNames.DrilldownStateID);
    }
  }

  return `?${queryParams.toString()}`;
};

export const getIsValidInput = (
  inputString: string, maxLength: number, minLength:number,
) => isWrongInput(inputString)
  || inputString.length > maxLength
  || inputString.trim().length < minLength;

export const getIsReportBuilderSaveFormDisable = (
  Title: string,
  Description: string,
) => getIsValidInput(Title, MAX_NAME_CHARACTER_LIMIT, MIN_CHARACTER_LIMIT) || getIsValidInput(Description, MAX_DESC_CHARACTER_LIMIT, MIN_CHARACTER_LIMIT);

export const getErrMsg = (
  inputString: string, type:string, Maxlimit:number,
) => {
  if (isWrongInput(inputString)) return `${useLocalize('err.inValidCharacterMessage')}`;

  if (inputString.length > Maxlimit) return `${useLocalize(`err.${type}MaxLength`)}`;

  if (inputString.trim().length < MIN_CHARACTER_LIMIT) return `${useLocalize(`err.${type}MinLength`)}`;

  return '';
};

export const convertColumnToDimension = (column: IColumn) : IDimension => {
  const props = column?.Props;

  return ({
    Name: column?.Name,
    IsMasked: props?.IsMasked,
    BuilderConfig: column?.BuilderConfig,
    Mode: props?.Mode,
    IsUserDynamicField: props.IsUserDynamicField,
    Props: props,
    ReferTo: props?.ReferTo,
    Applied: props?.Dimension?.Applied,
    Orderable: props?.Dimension?.Orderable,
    ZeroAnchor: props?.Dimension?.ZeroAnchor,
    DimensionMode: props?.Dimension?.DimensionMode,
    Id: column.Id,
  });
};

export const getUpdatedSortObjectForSave = (dimensions: IDimension[], sort:ISorting[]) => {
  const sorting: ISorting[] = [];

  sort.forEach((item:ISorting) => {
    const dimension = dimensions.find((dim) => dim?.Id === item.Id);
    if (dimension) {
      sorting.push({
        Field: dimension?.BuilderConfig?.IsDynamicField ? (dimension.BuilderConfig.DisplayName || dimension?.Name) : dimension?.Name,
        Direction: item.Direction,
        Id: dimension.Id,
      });
    } else {
      sorting.push({
        Field: item.Field,
        Direction: item.Direction,
        Id: item.Id,
      });
    }
  });

  return sorting;
};

export const getInitialKey = (type: VisualizationTypes) => {
  switch (type) {
    case VisualizationTypes.PieChart:
    case VisualizationTypes.DonutChart:
      return ChartAttributesKey.Pie;
    case VisualizationTypes.LineChart:
    case VisualizationTypes.AreaChart:
      return ChartAttributesKey.Line;
    case VisualizationTypes.Table:
      return ChartAttributesKey.Table;
    default:
      return ChartAttributesKey.Column;
  }
};

// Create a list of entities names which are present in the dimensions and measures.
// @fieldList -> list of dimensions or measure from which entity needs to be fetch.
// return an sorted array of string as entity name.
export const getEntityList = <T extends IDimension | IColumn>(fieldsList: T[]) : string[] => {
  const entityList: string[] = [];
  if (isValidArrayAndNotEmpty(fieldsList)) {
    fieldsList.forEach((item) => {
      if (item?.BuilderConfig?.Entity) {
        entityList.push(item?.BuilderConfig?.Entity);
      }
    });
  }
  return uniq(entityList).sort();
};

// Update the Columns object with the active dimensions and returns an updated list of columns
// @Columns: Array of columns.
// @ActiveDimensions: Array of all applied dimensions
// @allMeasures: Array of all measures.
// @dynamicDimensions: Array of all dynamicDimensions.
export const getColumns = (
  columns: ReportResponseModel.IColumn[], activeDimensions: ReportReduxModel.IDimension[],
  allMeasures: ReportResponseModel.IColumn[], dynamicDimensions:ReportResponseModel.IColumn[],
):IColumn[] => {
  if (!isEmpty(columns) && !isEmpty(activeDimensions)) {
    return getColumnsForActiveDimensions(columns, activeDimensions, allMeasures, dynamicDimensions).map(removeBuilderPropForMeasure);
  }

  return isValidArrayAndNotEmpty(columns) ? columns.map(removeBuilderPropForMeasure) : [];
};

// Payload Creation for report and export done in report page, Returns the complete object as ReportFetchCriteria
// @Input: Take all the redux stores value and create the required payload.
export const getReportDataLoadPayload = (filterConfig: Array<ReportResponseModel.IFilterConfig>,
  filters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, reportId: string,
  sort: Array<ReportResponseModel.ISortedField>, page: ReportResponseModel.IPage,
  columns: Array<ReportResponseModel.IColumn>, activeDimensions: Array<ReportReduxModel.IDimension>,
  allMeasures: Array<ReportResponseModel.IColumn>, dynamicDimensions:Array<ReportResponseModel.IColumn>): ReportFetchCriteria => {
  const data = {
    ReportId: reportId,
    Filters: {},
    FiltersMetadata: {},
    Page: page,
    Columns: getColumns(columns, activeDimensions, allMeasures, dynamicDimensions),
    SelectedMeasure: '',
    SelectedRow: [],
    Sort: rebuildSortState(sort, activeDimensions, getAppliedMeasures(columns), []),
  } as ReportFetchCriteria;

  return getUpdatedReportData(filterConfig, filters, data);
};

// Get Updated data Object, updates the filters and filterMetaData, Returns the updated data Object.
// @filterConfig: Filter Config stored in store.
// @filters: Array of all applied filters.
// @data: ReportFetch Payload data Object.
export const getUpdatedReportData = (filterConfig: Array<ReportResponseModel.IFilterConfig>, filters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, data: ReportFetchCriteria) => {
  const updatedData = cloneDeep(data);
  updatedData.Filters = isValidArrayAndNotEmpty(filterConfig) ? filterConfig.reduce((acc: ObjModel.Obj, item) => {
    // Remove Disabled filters
    if (filters[item.ID] && !filters[item.ID].Disabled) {
      if (item.Type === FilterType.UserMultiSelect) {
        acc[item.ID] = filters[item.ID].FilterResponse.UserIds;
        updatedData.FiltersMetadata.UserDropdownSelectType = filters[item.ID].FilterResponse.Type;
      } else {
        acc[item.ID] = filters[item.ID].FilterResponse;
      }
    }
    return acc;
  }, {}) : [];

  return updatedData;
};

// Payload Creation for drilldown and export done in drilldown report, Returns the complete object as ReportFetchCriteria
// @Input: Take all the redux stores value and create the required payload.
export const getDrilldownReportDataLoad = (reportId: string, autodrilldownData: ReportReduxModel.IAutoDrilldownData,
  filterConfig: Array<ReportResponseModel.IFilterConfig>, columns: Array<ReportResponseModel.IColumn>,
  activeDimensions: Array<ReportReduxModel.IDimension>, measures: Array<ReportResponseModel.IColumn>,
  activeFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>, dynamicDimensions:Array<ReportResponseModel.IColumn>): ReportFetchCriteria => {
  const selectedRows: SelectedRow[] = [];
  if (isValidArrayAndNotEmpty(activeDimensions)) {
    activeDimensions?.map((dim, index) => {
      if (dim.Applied !== DimensionStatus.NotApplied) {
        let considerDim = false;
        const selectedRowData = autodrilldownData?.selectedRowData;
        const isColumnTotalSelected = autodrilldownData?.clickedOnColumnTotal;
        const isRowTotalSelected = autodrilldownData?.clickedOnRowTotal;
        const isSubTotalClicked = autodrilldownData?.clickedOnSubTotal;
        const isGroupedSubTotalClicked = autodrilldownData?.clickedOnGroupedSubTotal;
        if (isRowTotalSelected && dim?.DimensionMode !== DimensionMode.ColumnGroup) considerDim = true;
        else if (!isRowTotalSelected && isColumnTotalSelected && dim?.DimensionMode === DimensionMode.ColumnGroup) considerDim = true;
        else if (!isRowTotalSelected && !isColumnTotalSelected) considerDim = true;

        if (isSubTotalClicked && index > 0 && dim?.DimensionMode === DimensionMode.RowGroup) considerDim = false;
        if (isGroupedSubTotalClicked && index > 1 && dim?.DimensionMode === DimensionMode.RowGroup) considerDim = false;
        if (considerDim) {
          const name = dim.BuilderConfig && dim.BuilderConfig.IsDynamicField ? dim.BuilderConfig.Alias : dim.Name;
          const entity = dim.BuilderConfig ? dim?.BuilderConfig?.Entity : '';
          const column = isValidArrayAndNotEmpty(columns) ? columns?.find((col) => (col.Id === dim.Id)) : null;
          const selectedRow: SelectedRow = {
            Alias: name,
            Entity: entity,
            Value: selectedRowData[dim.Id] === DimensionNoValue ? '' : selectedRowData[dim.Id],
            IsMasked: column?.Props?.IsMasked,
            Id: dim.Id,
          };
          selectedRows.push(selectedRow);
        }
      }
      return null;
    });
  }

  const updatedMeasures = getUpdatedMeasures(measures, columns);

  const groupedDrilldownFields : IColumn[] = isValidArrayAndNotEmpty(autodrilldownData?.drilldownFields) ? autodrilldownData?.drilldownFields?.filter((dim) => dim?.Props?.Dimension?.Applied === DimensionStatus.Applied) : null;
  const fetchDrillDownRequest = {
    ReportId: reportId,
    Filters: {},
    Page: autodrilldownData.page,
    FiltersMetadata: {},
    Sort: autodrilldownData.sort,
    Columns: getColumns(columns, activeDimensions, updatedMeasures, dynamicDimensions),
    SelectedMeasure: autodrilldownData.selectedMeasure,
    SelectedRow: autodrilldownData.rowPinnedClicked ? [] : selectedRows,
    DrilldownColumns: groupedDrilldownFields,
  } as ReportFetchCriteria;

  return getUpdatedReportData(filterConfig, activeFilters, fetchDrillDownRequest);
};

export const isUserAggregation = (expr?: IExpression) => {
  const col = findUnderlyingColumn(expr);
  return expr && expr.Type && isAgg(expr) && col && col.PrependTableName && col.TableNameOverride === usersPrefix;
};

export const isDrilldownPossible = (expr?: IExpression) => expr && expr.Type && isAgg(expr) && !isUserAggregation(expr);

export const setDrilldownEnableFlag = (measure: IColumn) => {
  const updatedMeasureInfo = { ...measure };
  const { Props } = updatedMeasureInfo;
  if (Props?.Measure?.IsPreDefined) {
    updatedMeasureInfo.Props.DrilldownEnabled = Object.prototype.hasOwnProperty.call(Props, 'DrilldownEnabled') ? updatedMeasureInfo?.Props?.DrilldownEnabled : measure?.Props?.AllowDrillDown;
  } else {
    const enableDrilldown = isDrilldownPossible(measure?.Props?.Measure?.Expression);
    if (enableDrilldown) {
      updatedMeasureInfo.Props.DrilldownEnabled = Object.prototype.hasOwnProperty.call(Props, 'DrilldownEnabled') ? updatedMeasureInfo?.Props?.DrilldownEnabled : enableDrilldown;
    } else {
      updatedMeasureInfo.Props.DrilldownEnabled = false;
    }
  }
  return updatedMeasureInfo;
};

export const getUniqIdentiferForDrilldownCols = (dim: IDimension | IColumn) => `${dim?.BuilderConfig?.Entity}:${dim?.BuilderConfig?.JoinID ? `${dim?.BuilderConfig?.JoinID}:` : ''}${dim?.BuilderConfig?.Alias}`;

export const getDefaultEntity = (reportConfig : IReportConfig) => {
  const defaultNamespace = reportConfig?.Builder[0]?.Namespace;
  const namespaceTypes = reportConfig?.ConfigMetadata?.namespaceTypes;
  if (!defaultNamespace || !namespaceTypes) return '';
  return namespaceTypes[defaultNamespace as keyof object] || '';
};
