import { VisualizationTypes } from 'core/constants/visualizations';
import {
  ColumnDataTypes, DimensionMode, DimensionStatus, FieldEntitiesType,
} from 'core/constants/report';
import { FilterType } from 'core/constants/filter';
import { DrilldownConfigMapping, IFilterMetaInfo } from 'redux-v2/report-builder/report-builder-store.state';
import { FeatureContext } from 'core/constants/common';
import {
  IChartVisualizationBuilder,
  IColumnProperties,
  IFiltersMetaData,
  IReportBuilderConfig,
  IVisualizationBuilder,
} from '../report-response';
import { ObjModel } from '..';
import { IExpression } from '../report-expressions';
import { IPanelVisualisation } from '../dashboard';
import { IDimension } from '../report-redux';

export interface ITemplateList {
  Namespace: string;
  TemplateId: string;
  Type: FieldEntitiesType;
  ReportConfig: IReportConfig;
  FilterMetaInfo: IFilterMetaInfo[];
  Settings: ITemplateSetting;
  TemplateMeta: ITemplateMeta;
  RawTemplateId?: string;
  Joins?: string;
}

export interface ITemplateSetting {
  PartitionColumnFilterDateLimit: number;
}

export interface IReportConfig {
  ReportConfigId: string;
  CategoryConfigId: string;
  DimensionsMetaFields: Array<IDimensionsMetaFields>;
  Industry: string;
  LSQReportId: string;
  DisableDrillDown: boolean;
  Version: string;
  Status: ReportBuilderStatus;
  Builder: Array<IBuilder>;
  SubBuilders: Array<IBuilder>;
  Paging: IPaging;
  CalculatedColumns: IBuilderMeasure[];
  EnabledOn: string;
  IsOpportunityRelated: boolean;
  Details: IDetails;
  Sorting: Array<ISorting>;
  Visualization: IPanelVisualisation;
  Columns: Array<IReportBuilderColumn>;
  FiltersMetadata?: IFiltersMetaData; // Filter Metadata will get appended while save, to track the user selected value
  ConfigMetadata?: IConfigMetaData;
  FeatureContext?: FeatureContext;
  drilldownConfigMapping?: DrilldownConfigMapping;
  DrilldownConfigIdMapping?: DrilldownConfigMapping;
  enableDrilldown?: boolean;
  drilldownPagination?: {
    PageIndex: number,
    PageSize: number,
  }
  SourceInfo: {
    Id: string,
    Type: string
  }
}

export interface IBuilder {
  Namespace: string;
  Select: IBuilderDimensionMeasure;
  Filter: IBuilderFilter;
  Hierarchy: IHierarchy;
  Joins?: Array<JoinItem>;
}

export interface IBuilderDimensionMeasure {
  Dimensions: Array<IBuilderDimension>;
  Measures: Array<IBuilderMeasure>;
}

export interface IBuilderDimension {
  Id: string;
  Alias: string;
  DataType: ColumnDataTypes;
  IsTypeAlteringExpression: boolean;
  GroupByExpression: IExpression;
  IsAdditional?: boolean;
}

export interface IBuilderMeasure {
  IsGrandTotal: boolean;
  MeasureExpression: IExpression;
  Alias: string;
  DataType: ColumnDataTypes;
  DrillDownSettings: IDrilldownSettings;
  IsCustomExpression: boolean;
  IsAdditional?: boolean;
  Id: string;
}

export interface IDrilldownSettings {
  Paging: null;
  Sorting: null;
  Columns: null;
}

export interface IBuilderFilter {
  FilterGroup: Array<IFilterGroup>;
  GroupOperator: string;
  SubFilters: Array<IBuilderFilter>;
}

export interface IFilterGroup {
  LinkedDimensionAlias: string;
  IsAdditional: boolean;
  AlwaysEnabled: boolean;
  ID: string;
  Alias: string;
  DataType: ColumnDataTypes;
  Operator: string; // enum to be added
  Render: IFilterGroupRender;
  JoinID?: string;
  LinkedTo?: string;
  IsUserIDFilter?: boolean;
  LinkedDimensionId?: string;
}

export interface IFilterGroupRender {
  ID: string;
  Type: FilterType;
  DefaultValue: any;
  Metadata?: ObjModel.Obj;
  LinkedTo: string;
  Label: string;
  StyleConfig: null;
}

export interface IDimensionsMetaFields {
  Dimension: string;
  Type: string;
  Fields: Array<IDimensionsMetaFieldItem>;
  Id: string;
}

export interface IDimensionsMetaFieldItem {
  SchemaName: string;
  Alias: string;
  Id: string;
}

export interface JoinItem {
  Type: JoinType;
  With: string;
  ID: string;
  On: IExpression;
  Primary?: string;
}

export enum JoinType {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  INNER = 'INNER',
  FULL = 'FULL'
}

export enum ReportBuilderStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export interface IHierarchy {
  ManagerFilterId: string;
  SubordinatesFilterId: string;
  ToUse: boolean;
  DimensionAlias: string;
  HierarchyField: string;
}

export interface IPaging {
  PageIndex: number;
  PageSize: number;
}

export interface IDetails {
  Title: string;
  Description: string;
  Type: ReportType;
}

export enum ReportType {
  System = 'System',
  Custom = 'Custom',
}
export enum ReportSourceInfo{
  Report = 'Report',
  Template = 'Template'
}
export interface ISorting {
  Field: string;
  Direction: SortingOrderTypes;
  Id: string;
  DimensionAlias?: string;
  DimensionType?: string;
  DimensionDataType?: string;
  DimensionRelatedFilterId?: string;
  IsDimensionMetadataField?: false;
  DimensionMetadataFieldSchemaName?: string;
}

export enum SortingOrderTypes {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IVisualization {
  Id: string;
  DisplayName: string;
  Type: VisualizationTypes;
  Builder: IVisualizationBuilder | IChartVisualizationBuilder;
}

export interface IReportBuilderColumn {
  Id: string;
  BuilderConfig?: IReportBuilderConfig;
  Name: string;
  Props: IColumnProperties;
}

export enum ReportBuilderTab {
  Data = 'Data',
  Filters = 'Filters',
  Charts = 'Charts',
  Drilldown = 'Drilldown',
}

export interface ISetGroupingPayload {
  sourceIndex: number;
  status: DimensionStatus;
  mode: DimensionMode;
  destinationIndex?: number;
  isSwapEnabled: boolean;
  customDimension?: IDimension
  isFieldSwitch?: boolean;
}

export interface ISetDimensionsBinningPayload {
  index: any;
  value: string;
}

export interface IConfigMetaData {
  namespaceTypes: Object;
  filterMetaInfo: IRawFilterMetaInfo;
}

export interface IRawFilterMetaInfo {
  partitionColumnFilterIds: string[]
}

export interface ITemplateMeta{
  TemplateTitle: string;
  GroupTitle: string[];
  Description: string[];
}
