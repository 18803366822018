import { ReduxModel } from 'core/models';
import { isValidArrayAndNotEmpty } from 'components/feature/Report/ReportSidebar/common/helpers';
import { IUpdatedReportBuilderInfo } from './report-builder-store.state';

const reportBuilderSelector = ({ reportBuilder }: ReduxModel.IGlobalState) => reportBuilder;

const reportPayloadSelector = ({
  reportBuilder: {
    updatedReportBuilderInfo: { reportBuilderSavePayload } = {} as IUpdatedReportBuilderInfo,
  },
}: ReduxModel.IGlobalState) => reportBuilderSavePayload;

const requestProcessingSelector = ({
  reportBuilder: { requestProcessing },
}: ReduxModel.IGlobalState) => requestProcessing;

const templateListSelector = ({
  reportBuilder: {
    reportBuilderInfo: { templateList },
  },
}: ReduxModel.IGlobalState) => templateList;

const reportBuilderInfoSelector = ({
  reportBuilder: {
    reportBuilderInfo,
  },
}: ReduxModel.IGlobalState) => reportBuilderInfo;

const assortedItemSelector = ({
  reportBuilder: { assortedInfo },
}: ReduxModel.IGlobalState) => assortedInfo;

const filterBuilderSelector = ({
  reportBuilder: { filterBuilder },
}: ReduxModel.IGlobalState) => filterBuilder;

const appliedFiltersSelector = ({
  reportBuilder: { filterBuilder: { appliedFilters } },
}: ReduxModel.IGlobalState) => appliedFilters;

const filterConfigSelector = ({
  reportBuilder: { filterBuilder: { filterConfig } },
}: ReduxModel.IGlobalState) => filterConfig;

const isUserModifiedSelector = ({
  reportBuilder: { filterBuilder: { isUsersModified } },
}: ReduxModel.IGlobalState) => isUsersModified;

const availableDimensionsSelector = ({
  reportBuilder: {
    updatedReportBuilderInfo: { appliedDimensions } = {} as IUpdatedReportBuilderInfo,
  },
}: ReduxModel.IGlobalState) => appliedDimensions;
const appliedMeasuresSelector = ({ reportBuilder: { updatedReportBuilderInfo: { appliedMeasures } } }: ReduxModel.IGlobalState) => appliedMeasures;
const sortStateSelector = ({ reportBuilder: { updatedReportBuilderInfo: { sortState } } }: ReduxModel.IGlobalState) => sortState;
const calculatedColumnsSel = ({ reportBuilder: { updatedReportBuilderInfo: { calculatedColumns } } }: ReduxModel.IGlobalState) => calculatedColumns;
const previewDataSelector = ({ reportBuilder: { previewData } }: ReduxModel.IGlobalState) => previewData;
const previewBakUpSelector = ({ reportBuilder: { previewBackUpInfo } }: ReduxModel.IGlobalState) => previewBackUpInfo;

const expressionStoreSelector = ({ reportBuilder: { updatedReportBuilderInfo: { expressionStore } } } : ReduxModel.IGlobalState) => expressionStore;

const combinedConfigLoadedSelector = ({ reportBuilder: { reportEditInfo: { combineConfigLoaded } } }: ReduxModel.IGlobalState) => combineConfigLoaded;
const isEditingSelector = ({ reportBuilder: { reportEditInfo: { isEditing } } }: ReduxModel.IGlobalState) => isEditing;
const drilldownConfigMappingSelector = ({ reportBuilder: { updatedReportBuilderInfo: { drilldownConfigInfo: { drilldownConfigMapping } } } }: ReduxModel.IGlobalState) => drilldownConfigMapping;
const drilldownConfigInfoSelector = ({ reportBuilder: { updatedReportBuilderInfo: { drilldownConfigInfo } } }: ReduxModel.IGlobalState) => drilldownConfigInfo;
const drilldownSortStateSelector = ({ reportBuilder: { updatedReportBuilderInfo: { drilldownConfigInfo: { drilldownColumnConfig: { sortState } } } } }: ReduxModel.IGlobalState) => sortState;
const drilldownEnabledStateSelector = ({ reportBuilder: { updatedReportBuilderInfo: { drilldownConfigInfo: { enableDrilldown } } } }: ReduxModel.IGlobalState) => enableDrilldown;
const drilldownPaginationSelector = ({ reportBuilder: { updatedReportBuilderInfo: { drilldownConfigInfo: { drilldownPagination } } } }: ReduxModel.IGlobalState) => drilldownPagination;
const fieldSelectors = ({ reportBuilder: { reportBuilderInfo: { fields } } }: ReduxModel.IGlobalState) => fields;
const calculatedColumnsSelector = ({ reportBuilder: { updatedReportBuilderInfo: { selectedTemplate: { ReportConfig: { CalculatedColumns } } } } }: ReduxModel.IGlobalState) => CalculatedColumns;
const builderMeasuresListSelector = ({ reportBuilder: { updatedReportBuilderInfo: { reportBuilderSavePayload } } }: ReduxModel.IGlobalState) => {
  const builderMeasures = reportBuilderSavePayload.Builder[0]?.Select?.Measures || [];
  const subBuilderMeasures = isValidArrayAndNotEmpty(reportBuilderSavePayload?.SubBuilders) ? reportBuilderSavePayload?.SubBuilders[0]?.Select?.Measures : [];
  return [...builderMeasures, ...subBuilderMeasures || []];
}; // TODO: subBuilders can be multiple items

const selectedReportConfig = ({ reportBuilder: { updatedReportBuilderInfo: { selectedTemplate: { ReportConfig } } } }: ReduxModel.IGlobalState) => ReportConfig;

const reportBuilderDynamicUserFields = ({ reportBuilder: { reportBuilderInfo: { additionalUserFields } } }: ReduxModel.IGlobalState) => additionalUserFields;

const dataSourceInfoSelector = ({ reportBuilder: { dataSourceInfo } }: ReduxModel.IGlobalState) => dataSourceInfo;

const dataSourceSidetrayPanelStateSelector = ({ reportBuilder: { isDataSourcePanelExpanded } }: ReduxModel.IGlobalState) => isDataSourcePanelExpanded;

const drilldownMeasuresSidetrayPanelStateSelector = ({ reportBuilder: { isDrilldownMeasuresPanelExpanded } }: ReduxModel.IGlobalState) => isDrilldownMeasuresPanelExpanded;

const drilldownFieldsSidetrayPanelStateSelector = ({ reportBuilder: { isDrilldownFieldsPanelExpanded } }: ReduxModel.IGlobalState) => isDrilldownFieldsPanelExpanded;

export {
  reportBuilderSelector,
  templateListSelector,
  requestProcessingSelector,
  reportPayloadSelector,
  assortedItemSelector,
  reportBuilderInfoSelector,
  availableDimensionsSelector,
  appliedMeasuresSelector,
  filterBuilderSelector,
  sortStateSelector,
  previewDataSelector,
  previewBakUpSelector,
  appliedFiltersSelector,
  filterConfigSelector,
  isUserModifiedSelector,
  calculatedColumnsSel,
  expressionStoreSelector,
  combinedConfigLoadedSelector,
  isEditingSelector,
  drilldownConfigMappingSelector,
  drilldownConfigInfoSelector,
  drilldownSortStateSelector,
  drilldownEnabledStateSelector,
  drilldownPaginationSelector,
  fieldSelectors,
  calculatedColumnsSelector,
  builderMeasuresListSelector,
  selectedReportConfig,
  reportBuilderDynamicUserFields,
  dataSourceInfoSelector,
  dataSourceSidetrayPanelStateSelector,
  drilldownMeasuresSidetrayPanelStateSelector,
  drilldownFieldsSidetrayPanelStateSelector,
};
