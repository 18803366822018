import React from 'react';

type Props = {
  width: number;
  height: number;

  stroke?:string;
  fill?: string;
}
const PinnedIconNew = ({
  width, height, stroke, fill,
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="se-pinned-report-icon"
  >
    <path id="1" d="M13 10.5H7V2.5H13V10.5Z" fill={fill} />
    <path fill={stroke} className="rect-stroke" d="M11.6665 3.33329V7.49996C11.6665 8.43329 11.9748 9.29996 12.4998 9.99996H7.49984C8.0415 9.28329 8.33317 8.41663 8.33317 7.49996V3.33329H11.6665ZM14.1665 1.66663H5.83317C5.37484 1.66663 4.99984 2.04163 4.99984 2.49996C4.99984 2.95829 5.37484 3.33329 5.83317 3.33329H6.6665V7.49996C6.6665 8.88329 5.54984 9.99996 4.1665 9.99996V11.6666H9.1415V17.5L9.97484 18.3333L10.8082 17.5V11.6666H15.8332V9.99996C14.4498 9.99996 13.3332 8.88329 13.3332 7.49996V3.33329H14.1665C14.6248 3.33329 14.9998 2.95829 14.9998 2.49996C14.9998 2.04163 14.6248 1.66663 14.1665 1.66663Z" />
  </svg>
);

PinnedIconNew.defaultProps = {
  stroke: 'var(--secondaryColor)',
  fill: 'var(--white)',
};

export default PinnedIconNew;
