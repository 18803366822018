import { APIRequestStatus } from 'core/constants/redux';
import { statusCodes } from 'core/constants/report';
import { VisualizationTypes } from 'core/constants/visualizations';
import {
  ReportResponseModel, ObjModel, FilterModel, ReportReduxModel,
} from 'core/models';
import { IPage } from 'core/models/filter';
import { ISortedField } from 'core/models/grid';
import { IReportConfig, SortingOrderTypes } from 'core/models/report-builder/report-builder';
import {
  IDrilldownPoint, IDimension, IAutoDrilldownData, IVisualization, IChartAttributes,
  ISetChartDataPayload,
} from 'core/models/report-redux';
import { IExportData, IField, IJobObject } from 'core/models/report-response';
import { IVariables } from 'core/models/store';
import { MeasureIdentifier } from 'redux-v2/report-builder/report-builder-store.state';

export interface IReportState {
    requestProcessing: IRequestProcessing;
    reportId: string;
    reportConfig: ReportResponseModel.IReportConfig;
    filterConfig: Array<ReportResponseModel.IFilterConfig>;
    appliedFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>;
    sort: Array<ReportResponseModel.ISortedField>;
    isMultiSort: boolean;
    page: ReportResponseModel.IPage;
    reportData: ReportResponseModel.IReportData;
    prevAppliedFilters: Array<ObjModel.ObjGeneric<FilterModel.IFilterResponse>>;
    activeFilters: ObjModel.ObjGeneric<FilterModel.IFilterResponse>;
    useChangedFilters: boolean;
    drilldownPoint: IDrilldownPoint;
    selectedToolbarMenuItem: number;
    columns: Array<ReportResponseModel.IColumn>;
    appliedDimensions: Array<IDimension>;
    activeDimensions: Array<IDimension>; // this is used to keep the applied count on the intial load to compare if user adds / modifies on vizualize tab.
    allMeasures: Array<ReportResponseModel.IColumn>; // All measures in play, including dynamic. State is dirty until 'Apply Settings' is clicked
    activeDimensionsLength:number
    dynamicDimensions: Array<ReportResponseModel.IColumn>;
    isDynamicMeasuresLoaded: boolean;
    zeroValuesEnabled: boolean;
    autodrilldownData: IAutoDrilldownData;
    autoDrilldownStateID: string;
    autoDrilldownDataV2: ReportReduxModel.IAutoDrilldownV2Data;
    pivotBinningType: string;
    exportData: IExportData;
    exportListData: Array<ReportResponseModel.IExportData>;
    triggerExport: string;
    isDailyLimitReached: boolean;
    totalDailyExports:number;
    exportsConsumed:number;
    jobObject: IJobObject;
    isResetClicked: boolean;
    isUsersModified: boolean;
    isFirstLoad: boolean;
    dynamicFilters: Array<ReportResponseModel.IFilterConfig>;
    reportName: string;
    reportDescription: string;
    reportNameSaveAs?: string;
    isFormDisabled: boolean;
    reportDescriptionSaveAs?: string;
    Visualization: IVisualization;
    selectedRowData: ObjModel.Obj;
    mappedMetaFields: object;
    showOthersInfoPopUp:boolean;
    showReport: boolean;
    pivotSort: SortingOrderTypes;
    savedReportConfig: IReportConfig;
    enablesSaveAs:boolean;
    trackUserDrilldown:boolean;
    accessStatusCode: statusCodes;
    fields:Array<IField>;
    applyClickCountDrilldown: number;
    applyClickCount: number;
    selectedChartData: ISetChartDataPayload;
}

export enum ReportActionTypes {
    REPORT_CONFIG_LOAD_REQUEST = 'REPORT_CONFIG_LOAD_REQUEST',
    GET_RAW_CONFIG_LOAD_REQUEST = 'GET_RAW_CONFIG_LOAD_REQUEST',
    SAVE_REPORT_CONFIG_REQUEST = 'SAVE_REPORT_CONFIG_REQUEST',
    FILTERS_LOAD = 'FILTERS_LOAD',
    FILTER_LOAD_REQUEST = 'FILTER_LOAD_REQUEST',
    REPORT_DATA_LOAD_REQUEST = 'REPORT_DATA_LOAD_REQUEST',
    FILTER_CHANGE = 'FILTER_CHANGE',
    PAGINATION_CHANGE = 'PAGINATION_CHANGE',
    SORTING_CHANGE = 'SORTING_CHANGE',
    CHANGE_PIVOT_SORTING = 'CHANGE_PIVOT_SORTING',
    DRILLDOWN_CHANGE = 'DRILLDOWN_CHANGE',
    REVERSE_DRILLDOWN = 'REVERSE_DRILLDOWN',
    APPLY_FILTERS = 'APPLY_FILTERS',
    SET_TOOLBAR_MENU_ITEM = 'SET_TOOLBAR_MENU_ITEM',
    SET_ZERO_ANCHOR = 'SET_ZERO_ANCHOR',
    SET_GROUPING = 'SET_GROUPING',
    APPLY_DIMENSIONS = 'APPLY_DIMENSIONS',
    REORDER_COLUMNS = 'REORDER_COLUMNS',
    SAVE_COLUMNS_ORDER_REQUEST = 'SAVE_COLUMNS_ORDER_REQUEST',
    SET_MEASURE_STATE = 'SET_MEASURE_STATE',
    UPDATE_MEASURE = 'UPDATE_MEASURE',
    REORDER_MEASURE = 'REORDER_MEASURE',
    TOGGLE_ZERO_ANCHORS = 'TOGGLE_ZERO_ANCHORS',
    SEARCH_DROPDOWN_OPTIONS = 'SEARCH_DROPDOWN_OPTIONS',
    LOAD_MORE_DROPDOWN_OPTIONS = 'LOAD_MORE_DROPDOWN_OPTIONS',
    RESET = 'RESET_REPORT',
    REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST = 'REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST',
    ADD_DYNAMIC_FILTER = 'ADD_DYNAMIC_FILTER',
    DELETE_DYNAMIC_FILTER = 'DELETE_DYNAMIC_FILTER',
    REFRESH = 'REFRESH',
    AUTODRILLDOWN = 'AUTODRILLDOWN',
    AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST = 'AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST',
    AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS = 'AUTODRILLDOWN_REPORT_DATA_LOAD_SUCCESS',
    AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE = 'AUTODRILLDOWN_REPORT_DATA_LOAD_FAILURE',
    AUTODRILLDOWN_V2 = 'AUTODRILLDOWN_V2',
    SET_BINNING = 'SET BINNING',
    SET_GROUPING_DRILLDOWN = 'SET_GROUPING_DRILLDOWN',
    EXPORT_DATA_LOAD_REQUEST = 'EXPORT_DATA_LOAD_REQUEST',
    EXPORT_USAGE_STATUS_REQUEST = 'EXPORT_USAGE_STATUS_REQUEST',
    EXPORT_LIST_LOAD_REQUEST = 'EXPORT_LIST_LOAD_REQUEST',
    EXPORT_LIST_LOAD_BY_IDS_REQUEST = 'EXPORT_LIST_LOAD_BY_IDS_REQUEST',
    RETRY_EXPORT_REQUEST = 'RETRY_EXPORT_REQUEST',
    UPDATE_TRIGGER_EXPORT = 'UPDATE_TRIGGER_EXPORT',
    DATA_REFRESH_DETAILS_LOAD_REQUEST = 'DATA_REFRESH_DETAILS_LOAD_REQUEST',
    DYNAMIC_FILTERS_LOAD_REQUEST = 'DYNAMIC_FILTERS_LOAD_REQUEST',
    REPORT_DYNAMIC_MEASURES_LOAD_REQUEST = 'REPORT_DYNAMIC_MEASURES_LOAD_REQUEST',
    SET_REPORT_INFO = 'SET_CURRENT_REPORT_INFO',
    SET_VISUALIZATION = 'SET_VISUALIZATION',
    RESET_SHOW_OTHERS_COLUMN_FLAG='RESET_SHOW_OTHERS_COLUMN_FLAG',
    SET_SHOW_REPORT='SET_SHOW_REPORT',
    ACCESS_CONTROL_REQUEST = 'ACCESS_CONTROL_REQUEST',
    DELETE_REPORT = 'DELETE_REPORT',
    DRILLDOWN_DIMENSIONS_LOAD_REQUEST = 'DRILLDOWN_DIMENSIONS_LOAD_REQUEST',
    AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST='AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST',
    DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST ='DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST',
    DRILLDOWN_SORTING_CHANGE = 'DRILLDOWN_SORTING_CHANGE',
    DATA_REFRESH_STATUS_LOAD_REQUEST='DATA_REFRESH_STATUS_LOAD_REQUEST',
    GET_EXPRESSION_FIELDS='GET_EXPRESSION_FIELDS',
    SET_APPLY_CLICK_COUNT='SET_APPLY_CLICK_COUNT',
    SET_APPLY_CLICK_DRILLDOWN_COUNT='SET_APPLY_CLICK_DRILLDOWN_COUNT',
    SET_VISUALIZATION_SELECTION='SET_VISUALIZATION_SELECTION',
}

export interface IRequestProcessing {
    [ReportActionTypes.REPORT_CONFIG_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.GET_RAW_CONFIG_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.SAVE_REPORT_CONFIG_REQUEST]: APIRequestStatus;
    [ReportActionTypes.FILTER_LOAD_REQUEST]: ObjModel.ObjGeneric<APIRequestStatus>;
    [ReportActionTypes.REPORT_DATA_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.REPORT_DYNAMIC_DIMENSIONS_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.AUTODRILLDOWN_REPORT_DATA_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.EXPORT_DATA_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.EXPORT_LIST_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.EXPORT_LIST_LOAD_BY_IDS_REQUEST]: APIRequestStatus;
    [ReportActionTypes.EXPORT_USAGE_STATUS_REQUEST]: APIRequestStatus;
    [ReportActionTypes.DATA_REFRESH_DETAILS_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.REPORT_DYNAMIC_MEASURES_LOAD_REQUEST]: APIRequestStatus
    [ReportActionTypes.DYNAMIC_FILTERS_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.ACCESS_CONTROL_REQUEST]: APIRequestStatus;
    [ReportActionTypes.DELETE_REPORT]: APIRequestStatus;
    [ReportActionTypes.REORDER_COLUMNS]: APIRequestStatus;
    [ReportActionTypes.AUTODRILLDOWN_V2]: APIRequestStatus;
    [ReportActionTypes.AUTODRILLDOWN_REPORTV2_DATA_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.SAVE_COLUMNS_ORDER_REQUEST]: APIRequestStatus;
    [ReportActionTypes.DASHBOARD_REDIRECTED_EXPORT_DATA_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.RETRY_EXPORT_REQUEST]: APIRequestStatus;
    [ReportActionTypes.DATA_REFRESH_STATUS_LOAD_REQUEST]: APIRequestStatus;
    [ReportActionTypes.GET_EXPRESSION_FIELDS]: APIRequestStatus;
}

export interface IFilterPayload {
  variables: IVariables,
  filterConfig : ReportResponseModel.IFilterConfig,
  dependentValue: any,
  value: any,
  meta?: any,
  filterPayload?: ObjModel.ObjGeneric<any>
}

export interface IFetchDrilldownPayload {
  selectedMeasure?: string,
  selectedRowData?: ObjModel.Obj,
  measureFieldAlias?: string,
  rowPinnedClicked?: boolean,
  clickedOnColumnTotal?: boolean,
  clickedOnRowTotal?: boolean,
  isRefresh?:boolean,
  page?: IPage,
  sort?:ISortedField[],
  clickedOnSubTotal?:boolean,
  clickedOnGroupedSubTotal?: boolean,
  selectedMeasureInfo?: MeasureIdentifier,
}

export interface IUpdateVisualizationPayload {
  type: VisualizationTypes;
  attributes: IChartAttributes;
}
