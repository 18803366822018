import styled from 'styled-components';

export interface IToastProps {
  isV1Report:boolean
}

const StyledToastComponent = styled.div<IToastProps>`

    z-index: 10000;
      
    .Toastify__toast-container--top-center{
      top: 1em;
      left: 50%;
      transform: translateX(-50%);
      width: ${(props) => (props.isV1Report ? '312px' : '')};
    }

    .Toastify .Toastify__toast {
      min-height: 40px;
      font-size: ${(props) => (props.isV1Report ? '12px' : '13px')};
      line-height: ${(props) => (props.isV1Report ? '16px' : '20px')};
      font-family: 'Regular';
      color: ${(props) => (props.isV1Report ? 'var(--lsqDataHeader)' : 'var(--secondaryColor)')};


      &.Toastify__toast--error{

        border: ${(props) => (props.isV1Report ? 'solid 1px var(--lsqToastBoard)' : 'solid 1px var(--dangerColor)')};
        background-color : ${(props) => (props.isV1Report ? 'var(--lsqToastBackground)' : 'var(--dangerColorBg)')};
        border-left: ${(props) => (props.isV1Report ? '' : 'solid 6px var(--dangerColor)')};
      }

      &.Toastify__toast--success{
        border: ${(props) => (props.isV1Report ? 'solid 1px var(--v1animationColor)' : 'solid 1px var(--animationGreenColor)')};
        background-color: ${(props) => (props.isV1Report ? 'rgba(126, 216, 112, 0.1)' : 'var(--animationGreenColorBg)')};
        border-left:${(props) => (props.isV1Report ? 'solid 1px var(--v1animationColor)' : 'solid 6px var(--animationGreenColor)')};
        border-radius: ${(props) => (props.isV1Report ? '0px' : '4px')};
      }

      &.Toastify__toast--warning{
        border: solid 1px var(--itemColor);
        background-color: var(--itemColorBg);
        border-left:solid 6px var(--itemColor);
      }
      &.Toastify__toast-icon{
        svg{
          width: 20px ;
          height: 20px;
        }
      }

      &.copy-action{
        width: 330px;
      } 

      &.redirect-error{
        width: 353px;
      }


      &.embed-url-error{
        width: 285px;
      }

      &.internal-redirect-error{
        width: 487px;
         &.v1{
          width: 330px;
        }

      }

      &.se-profile{
        width: 324px;
      }

      &.se-dashboard-error{
        width: 355px;
      }

      &.se-dashboard-build-success{
        width: 321px;
      }

      &.se-expression-delete-success{
        width: 335px;
      }

      &.se-report-build-success{
        width: 316px;
      }

      &.se-report-delete-success{
        width: 297px;
      }

      &.se-dashboard-build-success-add{
        width: 341px;
      }
      
      &.se-profile-update{
        width: 304px;
      }

      &.pivot-notify{
        width: 445px;
      }

      &.tag-action{
        width: 350px;
        
        &.se-dashboard{
          width: 365px;
        }
      }

      &.add-widget{
        width: 240px;
      }
      &.v1-toast{
        width: 320px;
      }

      &.se-report-edit-success{
        width: 291px;
      }

      &.se-schedule-edit-success{
        width: 260px;
      }
      
      &.se-schedule-create-success{
        width: 340px;
      }

      &.owner-update-success-report{
        width: 510px;
      }
      &.owner-update-success-dashboard{
        width: 540px;
      }
      &.data-update-success-report {
        width: 340px;
      }
    }

    .Toastify .Toastify__toast-body{
      padding: 0px;
    }

    @media (min-width: 320px) {
      .Toastify__toast-container--top-center{
        max-width: 420px;
      }
    }

    @media (min-width: 768px) {
      .Toastify__toast-container--top-center{
        max-width: 600px;
      }
    }

    @media (min-width: 1024px) {
      .Toastify__toast-container--top-center{
        max-width: 600px;
      }
    }

    .se-icon-cn{
      margin-right: 5px;
      margin-left: 10px;
    }
`;

export default StyledToastComponent;
